// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "reducer";
// import thunk from "redux-thunk";

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: [thunk],
// })
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "reducer";
// Correct the import from redux-thunk
import { thunk } from "redux-thunk";  // Named import for `thunk`

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});
