import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  checkHeihlight,
  executeDataVerification,
  generateColumnGrouping,
  getColumnStyle,
  isEditable,
  toastMessage,
} from "layouts/linelist/XL_Utils";
import { colGrpMatrix } from "layouts/linelist/colGrpVariable";
import {
  setHighlightByTblName,
  compareAndHighLightDataChanges,
  GetAutoChanges,
} from "layouts/linelist/getChangesComman";
import { Grid, Autocomplete, TextField } from "@mui/material";
const tbl_name = "xl_faherty_matrix";
const rng_name = "Matrix_Rng";
const MatrixTab = forwardRef(({ setCurrentGridApi }, ref) => {
  const [autoChangeState, setAutoChangeState] = useState(false);
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const [isGridReady, setIsGridReady] = useState(false);
  const [MatrixColDef, setMatrixColDef] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const cellValueChanged = useRef([]);
  const columnGrpPref = useSelector((s) => s.MatrixGrpColPref);
  const columnPref = useSelector((s) => s.MatrixColPref);
  const dispatch = useDispatch();
  const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const highlightCells = useRef([]);
  const MatrixData = useSelector((s) => s.MatrixData);
  const MatrixHighlightCell = useSelector((s) => s.MatrixHighlightCell);
  const MatrixSsnData = useSelector((s) => s.MatrixSsnData);
  const tmpColDef = useSelector((s) => s.MatrixColDef || []);
  const varFilters = useSelector((s) => s.MatrixFilters || {});
  const varSorting = useSelector((s) => s.MatrixSorting || []);
  const [Dropdown, setDropdown] = useState({
    year: [],
    season: [],
    department: [],
    matrix: [
      "FP WEEKS MATRIX",
      "Size Count and Mix",
      "SSN Code Coverage Matrix",
      "Return Rate/Coverage Matrix",
      "Store Counts Matrix",
      "Disc Rate Coverage",
      "MD ST Override",
    ],
  });
  const [SelectedDropdown, setSelectedDropdown] = useState({
    year: "",
    season: "",
    department: "",
    matrix: "",
  });
  const seletectedMatrix = useSelector((s) => s.seletectedMatrix || []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (tmpColDef && tmpColDef.length > 0) {
      const hdrNmReplace = {
        "FP WEEKS MATRIX - ": "",
        "Size Count and Mix - ": "",
        "SSN Code Coverage Matrix - ": "",
        "Return Rate/Coverage Matrix - ": "",
        "MD ST Override - ": "",
        "Disc Rate Coverage - Full Price ": "",
        "Store Counts Matrix - ": "",
        "Disc Rate Coverage - Final Full Price ": "Final ",
        "Disc Rate Coverage - Markdown Price ": "",
        "Disc Rate Coverage - Final Markdown Price ": "Final ",
        "Disc Rate Coverage - ": "",
      };
      const processedColumns = tmpColDef.map((col) => {
        let mpCol = { ...col };
        mpCol.suppressColumnsToolPanel = col.hide;
        mpCol.suppressFiltersToolPanel = col.hide;
        Object.keys(hdrNmReplace).forEach((key) => {
          if (mpCol.field.includes(key)) {
            mpCol.headerName = mpCol.headerName.replace(key, hdrNmReplace[key]);
          }
        });
        mpCol.cellStyle = (params) => funCellStyle(params, col);
        return mpCol;
      });
      setMatrixColDef(generateColumnGrouping(colGrpMatrix, processedColumns));
    }
    const uniqueValues = (field) => [...new Set(MatrixData.map((item) => item[field]))];
    const year = uniqueValues("Year");
    const seasonCode = uniqueValues("Season Code");
    const department = uniqueValues("Department");
    setDropdown((prevVal) => ({
      ...prevVal,
      year: year,
      season: seasonCode,
      department: department,
    }));
    console.log(seletectedMatrix);
    if (seletectedMatrix.matrix === "" || seletectedMatrix.length === 0) {
      setSelectedDropdown((prevVal) => ({
        ...prevVal,
        year: year.length > 0 && prevVal.year === "" ? year[0] : prevVal.year,
        season: seasonCode.length > 0 && prevVal.season === "" ? seasonCode[0] : prevVal.season,
        department:
          department.length > 0 && prevVal.department === "" ? department[0] : prevVal.department,
        matrix: prevVal.matrix === "" ? Dropdown.matrix[0] : prevVal.matrix,
      }));
    } else setSelectedDropdown(seletectedMatrix);
  }, [MatrixData]);
  useEffect(() => {
    highlightCells.current = MatrixHighlightCell;
    if (isGridReady) gridApi.current.refreshCells({ force: true });
  }, [MatrixHighlightCell]);
  const funCellStyle = (params, col) => {
    if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
    if (!params?.node?.data && !params?.node?.data?.row_id) return;
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    let fontStyle = "normal";
    const customCode = getColumnStyle(tmpColDef, value, params, col);
    if (customCode.status) {
      bgColor = customCode.bgColor;
      bdrColor = customCode.bdrColor;
      txtDeco = customCode.txtDeco;
      fontColor = customCode.fontColor;
      boldTxt = customCode.boldTxt;
      borderBottom = customCode.borderBottom;
      fontStyle = customCode.fontStyle;
    } else {
      const editable = isEditable(params, col.field, col.editable, col.insert_editable);
      if (editable) bgColor = "#FFFFFF";
      else bgColor = "#F0F0F0";
      if (col.type === "dropdown") bgColor = "#FDFCEC";
    }
    // let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let key = tbl_name + "|" + col.field.trim() + "|" + params.node.data.row_id;
    let obj = checkHeihlight(key, highlightCells.current);
    if (obj.status === true) {
      if (obj.colorCode === 1) bgColor = "cyan";
      else if (obj.colorCode === 2) bgColor = "#d5a7f2";
      else if (obj.colorCode === 3) bdrColor = "#2196f3";
      else if (obj.colorCode === 4) bdrColor = "#9B6155";
      else if (obj.colorCode === 5) bgColor = "#CAE2F9";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) {
      bgColor = "#C7F9CC";
      boldTxt = "bold";
      txtDeco = "";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) {
      bgColor = "#FBF8CC";
      boldTxt = "bold";
      txtDeco = "";
    }
    if (bdrColor !== "")
      return {
        backgroundColor: bgColor,
        fontWeight: boldTxt,
        textDecoration: txtDeco,
        color: fontColor,
        borderColor: bdrColor,
        fontStyle: fontStyle,
      };
    else
      return {
        backgroundColor: bgColor,
        fontWeight: boldTxt,
        textDecoration: txtDeco,
        color: fontColor,
        borderBottomColor: borderBottom,
        fontStyle: fontStyle,
      };
  };
  const onFilterChanged = () => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "MatrixFilters", data: savedFilterModel });
  };
  const onSortChanged = () => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "MatrixSorting", data: savedSortState });
  };
  const setResetFilter = (s) => {
    gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!MatrixData) return false;
      const res = executeDataVerification(
        null,
        tbl_name,
        highlightCells.current,
        gridApi.current,
        gridColumnApi.current
      );
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        gridApi.current.refreshCells({ force: true });
        return res;
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return { status: true };
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(
        masterData,
        rng_name,
        tbl_name,
        tmpColDef,
        gridApi.current,
        cellValueChanged.current,
        highlightCells.current,
        metaData
      );
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "MatrixData", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(
        data,
        highlightCells.current,
        gridApi.current,
        cellValueChanged.current,
        tbl_name,
        downloadType
      );
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
      setResetFilter("set");
    },
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  const calculate_FP_WEEKS_MATRIX_Concat = (event) => {
    const cn1 = "FP WEEKS MATRIX - Season Lifecycle Code";
    const cn2 = "FP WEEKS MATRIX - RO CODE";
    const cn3 = "FP WEEKS MATRIX - Delivery Month";
    const cv1 = event.data[cn1] ? event.data[cn1] : "";
    const cv2 = event.data[cn2] ? event.data[cn2] : "";
    const cv3 = event.data[cn3] ? event.data[cn3] : "";
    const resultVal = cv1 + cv2 + cv3;
    return resultVal;
  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => {
        calculateReactFormula(event);
      });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    const cn1 = "FP WEEKS MATRIX - Concat";
    fxcalc(cn1) ? (event.data[cn1] = calculate_FP_WEEKS_MATRIX_Concat(event)) : null;
  };
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    calculateAllFormulas();
    setIsGridReady(true);
  };
  const cmbChange = (event, newselect, ids) => {
    let val = newselect || "";
    setSelectedDropdown((prevValues) => ({ ...prevValues, [ids]: val }));
  };
  useEffect(() => {
    dispatch({ type: "seletectedMatrix", data: SelectedDropdown });
  }, [SelectedDropdown]);

  return (
    <>
      <Grid container spacing={1} padding={0.8}>
        <Grid item sm={2}>
          <Autocomplete
            // multiple
            value={SelectedDropdown.year || ""}
            options={Dropdown["year"]}
            renderInput={(params) => <TextField {...params} label="Year" size="small" />}
            onChange={(event, newselect) => cmbChange(event, newselect, `year`)}
          />
        </Grid>
        <Grid item sm={2}>
          <Autocomplete
            // multiple
            value={SelectedDropdown.season || ""}
            options={Dropdown["season"]}
            renderInput={(params) => <TextField {...params} label="Season" size="small" />}
            onChange={(event, newselect) => cmbChange(event, newselect, `season`)}
          />
        </Grid>
        <Grid item sm={2}>
          <Autocomplete
            // multiple
            value={SelectedDropdown.department || ""}
            options={Dropdown["department"]}
            renderInput={(params) => <TextField {...params} label="Department" size="small" />}
            onChange={(event, newselect) => cmbChange(event, newselect, `department`)}
          />
        </Grid>
        <Grid item sm={6}>
          <Autocomplete
            // multiple
            value={SelectedDropdown.matrix || ""}
            options={Dropdown["matrix"]}
            renderInput={(params) => <TextField {...params} label="Matrix" size="small" />}
            onChange={(event, newselect) => cmbChange(event, newselect, `matrix`)}
          />
        </Grid>
        <Grid item sm={12}>
          <div
            style={{
              height: windowDimensions.height - 190,
            }}
          >
            <CustomAgGrid
              autoChangeState={autoChangeState}
              autoGetChangeResult={autoGetChangeResult}
              columnDefs={tmpColDef}
              customColumnDef={MatrixColDef}
              rowData={MatrixData}
              filters={varFilters}
              isGrouppedHeader={true}
              SSNData={MatrixSsnData}
              // calculateBottomRow={calcBottomRow}
              // pinnedBottomRowData={bottomRowData}
              onFilterChanged={onFilterChanged}
              onSortChanged={onSortChanged}
              resteAutoChangeState={resteAutoChangeState}
              setCellValueChanged={setCellValueChanged}
              calculateFormula={calculateReactFormula}
              setCurrentGridApi={onGridReady}
              tab_name="MATRIX"
              tbl_name={tbl_name}
              tblHighlightCells={MatrixHighlightCell}
              columnGrpPref={columnGrpPref}
              columnPref={columnPref}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
});

MatrixTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
};

export default MatrixTab;
