import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applicationTitle, restApiPath, utcDifference } from "utils/app.props";
import { CH1, GetTableDataLocale, cstFilterOptions, getColumnIndex, toastMessage } from "../../XL_Utils";

const ManageUsersColsDef =
    [
        { field: "row_id", headerName: "row_id", width: 140, hide: true },
        { field: "user_id", headerName: "user_id", width: 140, hide: true },
        {
            field: "first_name",
            headerName: "First Name",
            colId: "first_name",
            width: 140,
            cellStyle: { 'background-color': '#F0F0F0' },
            autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "last_name",
            headerName: "Last Name",
            colId: "last_name",
            width: 140,
            cellStyle: { 'background-color': '#F0F0F0' },
            autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "email", headerName: "User Name", width: 220, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "email",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "user_type", headerName: "User Type", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "user_type",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        }
    ]
const tbl_name = "app_user";

const ManageUsersTab = forwardRef(({ sendDataChangesBackend, setCurrentGridApi }, ref) => {
    const dispatch = useDispatch();
    const ManageUsersData = useSelector((s) => s.ManageUserData);
    const ManagePermissionData = useSelector((s) => s.ManagePermissionData);
    const ManageUserShorting = useSelector((state) => state.ManageUserShorting ? state.ManageUserShorting : []);
    const ManageUserFilters = useSelector((state) => state.ManageUserFilters ? state.ManageUserFilters : []);

    const nevigate = useNavigate();

    const [userTypes, setUserTypes] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState("");

    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);

    const FbFtyDatabaseData = useSelector(s => s.FbFtyDatabaseData);
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState("");
    const [isFactoryVisible, setIsFactoryVisible] = useState(false);

    const [isAddNewUser, setIsAddNewUser] = useState(false);
    const [isUpdateUser, setIsUpdateUser] = useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [email, setEmail] = useState('');

    const [emailHelperText, setEmailHelterText] = useState('');
    const [firstNameHelperText, setFirstNameHelterText] = useState('');
    const [lastNameHelperText, setLastNameHelterText] = useState('');

    const [showError, setShowError] = useState(false);
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);

    const gridApi = useRef({});
    const gridColumnApi = useRef({});


    const filterByCellValue = (p) => {
        let column = p.column.colDef.field;
        let value = `${p.value}`;
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: [value],
                    filterType: "set"
                }
            ]
        };
        ManageUserFilters[column] = newFilterItem;
        gridApi.current.setFilterModel(ManageUserFilters);
        gridApi.current.onFilterChanged();
    };
    const clearFilterByCellValue = (p) => {
        let column = p.column.colDef.field;
        delete ManageUserFilters[column];
        gridApi.current.setFilterModel(ManageUserFilters);
        gridApi.current.onFilterChanged();
    };
    const clearFilterBtnCall = () => {
        dispatch({ type: "SET_ManageUser_FILTERS", data: [] });
        gridApi.current.setFilterModel(null);
    };

    const onManageUsersGridReady = (params) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;

        gridApi.current.autoSizeAllColumns()
        setCurrentGridApi(params)

        if (ManageUserShorting !== null && ManageUserShorting !== undefined)
            gridApi.current.applyColumnState({ state: ManageUserShorting })
        if (ManageUserFilters !== null && ManageUserFilters !== undefined)
            gridApi.current.setFilterModel(ManageUserFilters);

        try {
            const cols = ManageUsersColsDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
        } catch (e) { console.log(e) }
    };
    const addNewUser = () => {
        setFirstNameValue("")
        setLastNameValue("")
        setEmail("")
        setSelectedFactory("")

        setSelectedUserType(userTypes[0].toString().toLowerCase())
        setSelectedDepartment(departments)
        setIsAddNewUser(true)
    };
    const updateUser = () => {
        // console.log("updateUser")
        if (email === null || email === undefined || email.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }

        const dt = ManageUsersData.filter((data) => data.email === email)

        if (dt && dt.length > 0) {
            const myData = dt[0]
            // console.log(myData)
            setFirstNameValue(myData.first_name)
            setLastNameValue(myData.last_name)
            setSelectedUserType(myData.user_type)

            setIsUpdateUser(true)
        }
    };
    const getUserData = () => {
        const postBody = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": tbl_name,
            "param_is_row_level": "no",
            "param_where": "",
            "param_locale_diff": utcDifference
        };

        GetTableDataLocale(JSON.stringify(postBody))
            .then(res => {
                if (res === "invalid_token") {
                    toastMessage("Session Expired", "warning");

                    sessionStorage.clear()
                    // sleep(3000).then(() => { window.location.reload() })
                    setTimeout(() => {
                        window.location.href = appUrl;
                    }, 3000)
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)

                } else {
                    dispatch({ type: "SET_ManageUser_SSN_DATA", data: JSON.parse(res.data) });
                    dispatch({ type: "SET_ManageUser_DATA", data: JSON.parse(res.data) });
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });

                }
            })
    }
    const openDeleteConfirmation = () => {
        if (email === null || email === undefined || email.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }
        setOpenDeleteConfirm(true)
    }
    const deleteUser = () => {
        // console.log("deleteUser")
        if (email === null || email === undefined || email.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        fetch(restApiPath + 'deleteUser?userName=' + email, {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            }
        })
            .then(response => response.json())
            .then(result => onDeleteResult(result));
    };
    const onDeleteResult = (result) => {
        if (result.error === "invalid_token") {
            toastMessage("Session Expired", "warning");

            setOpenDeleteConfirm(false)
            sessionStorage.clear();
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                //remove rows to manage permission and perform send data
                const chk = ManagePermissionData.filter((dt) => dt["Email"] === email);
                if (chk && chk.length > 0) {
                    const permissionData = ManagePermissionData.filter((dt) => dt["Email"] !== email);

                    dispatch({ type: "SET_ManagePermission_DATA", data: permissionData });
                    sendDataChangesBackend("xl_user_data_access", false)
                }
                toastMessage("User deleted successfully.", "success");

                closeCreateProfile()
                setFirstNameValue("")
                setLastNameValue("")
                setEmail("")
                setOpenDeleteConfirm(false)

                getUserData()
            } else {
                toastMessage("There is an error while deleting user.", "warning");

                closeCreateProfile()
                setFirstNameValue("")
                setLastNameValue("")
                setEmail("")
            }
        }
    }
    const verifyEmail = (value) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    const onEmailChange = (evt) => {
        const txt = (evt.target.value).toLowerCase();
        if (verifyEmail(txt)) {
            setEmailHelterText("");
            setShowError(false);
        } else {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
        }
        setEmail(txt);
    };
    const onFNChange = (evt) => {
        const txt = (evt.target.value);
        if (txt && txt !== "") {
            setFirstNameHelterText("");
            setShowFirstNameError(false);
        } else {
            setFirstNameHelterText("Please Enter First Name");
            setShowFirstNameError(true);
        }
        setFirstNameValue(txt);
    };
    const onLNChange = (evt) => {
        const txt = (evt.target.value);
        if (txt && txt !== "") {
            setLastNameHelterText("");
            setShowLastNameError(false);
        } else {
            setLastNameHelterText("Please Enter Last Name");
            setShowLastNameError(true);
        }
        setLastNameValue(txt);
    };
    const closeCreateProfile = () => {
        setIsAddNewUser(false)
        setIsFactoryVisible(false)
    };
    const updateUserProfile = () => {
        // varify user input

        if (firstNameValue === "") {
            setFirstNameHelterText("Please Enter First Name");
            setShowFirstNameError(true);
            return
        }
        if (lastNameValue === "") {
            setLastNameHelterText("Please Enter Last Name");
            setShowLastNameError(true);
            return
        }
        if (!verifyEmail(email)) {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
            return
        }
        if (selectedUserType === "" || !selectedUserType) {
            toastMessage("Please Select User Type", "warning");

            return
        }

        if (selectedUserType === "vendor" && (selectedFactory === "" || !selectedFactory)) {
            toastMessage("Please select factory for vendor user", "warning");

            return
        }

        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        fetch(restApiPath + 'updateUser', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            },
            body: JSON.stringify({
                "userName": email,
                "firstname": firstNameValue,
                "lastname": lastNameValue,
                "user_type": selectedUserType.toLowerCase(),
                "password": "123",
                // "userRole": selectedDepartment && selectedDepartment.length > 0 ? selectedDepartment.join(",") : "",
                "user_status": "Active",
                "emailTitle": applicationTitle
            })
        })
            .then(response => response.json())
            .then(result => onAddUpdateUserProfileResult(result));
    };
    const addUserProfile = () => {
        // varify user input

        if (firstNameValue === "") {
            setFirstNameHelterText("Please Enter First Name");
            setShowFirstNameError(true);
            return
        }
        if (lastNameValue === "") {
            setLastNameHelterText("Please Enter Last Name");
            setShowLastNameError(true);
            return
        }
        if (!verifyEmail(email)) {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
            return
        }
        if (selectedUserType === "" || !selectedUserType) {
            toastMessage("Please Select User Type", "warning");

            return
        }
        if (!selectedDepartment || selectedDepartment.length === 0) {
            toastMessage("Please select at least 1 department", "warning");

            return
        }
        if (selectedUserType === "vendor" && (selectedFactory === "" || !selectedFactory)) {
            toastMessage("Please select factory for vendor user", "warning");

            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        fetch(restApiPath + 'addUser', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            },
            body: JSON.stringify({
                "userName": email,
                "firstname": firstNameValue,
                "lastname": lastNameValue,
                //"user_type": "admin",
                "user_type": selectedUserType.toLowerCase(),
                "password": "123",
                "userRole": selectedDepartment && selectedDepartment.length > 0 ? selectedDepartment.join(",") : "",
                "user_status": "Active",
                "emailTitle": applicationTitle
            })
        })
            .then(response => response.json())
            .then(result => onAddUpdateUserProfileResult(result));
    };
    const onAddUpdateUserProfileResult = (result) => {
        if (result.error === "invalid_token") {
            toastMessage("Session Expired", "warning");

            sessionStorage.clear();
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                let msg = "added"
                if (isUpdateUser) msg = "updated"
                toastMessage("User " + msg + " successfully.", "success");

                if (isUpdateUser) setIsUpdateUser(false)
                else closeCreateProfile()

                if (msg === "added") {
                    //add rows to manage permission and perform send data
                    let permissionData = ManagePermissionData;
                    selectedDepartment.map((dept) => {
                        const pJson = {
                            "row_id": null,
                            "user_id": email,
                            "Email": email,
                            "Department": dept,
                            "Category": "",
                            "Sub Category": "",
                            "Factory": selectedFactory,
                            "Location": "",
                            "Style Activity": selectedUserType.toLowerCase() === "vendor" ? "ACTIVE" : "",
                        }
                        permissionData = [...permissionData, pJson];
                    })
                    dispatch({ type: "SET_ManagePermission_DATA", data: JSON.parse(JSON.stringify(permissionData)) });
                    sendDataChangesBackend("xl_user_data_access", false)
                } else {
                    const oldUser = ManageUsersData.filter((user) => user.email === email);
                    if (oldUser[0].user_type !== selectedUserType) {
                        //if changed user type to vendor then populate factory in all department

                        let permissionData = ManagePermissionData;
                        permissionData.map((up) => {
                            if (up["Email"] === email) {
                                up["Factory"] = selectedFactory ? selectedFactory : "";
                            }
                            return up;
                        })
                        dispatch({ type: "SET_ManagePermission_DATA", data: JSON.parse(JSON.stringify(permissionData)) });

                    }
                }
                setFirstNameValue("")
                setLastNameValue("")
                setEmail("")
                setSelectedUserType("")
                setIsFactoryVisible(false)
                setSelectedFactory("")
                setSelectedDepartment([])

                getUserData()
            } else {
                let msg = "There is an error while adding user."
                if (result.message) msg = result.message
                toastMessage(msg, "warning");

                closeCreateProfile()

                setFirstNameValue("")
                setLastNameValue("")
                setEmail("")
                setSelectedUserType("")
                setIsFactoryVisible(false)
                setSelectedFactory("")
                setSelectedDepartment([])
            }
        }
    }
    const onCellFocused = (event) => {
        if (event.rowIndex !== null && event.rowIndex !== undefined) {
            let row = event.api.getDisplayedRowAtIndex(event.rowIndex);
            if (row !== null && row !== undefined) {
                if (row.data !== null && row.data !== undefined) {
                    setEmail(row.data["email"])
                }
            }
        }
    }
    const habdelTypeChange = (event, newValue) => {
        if (newValue.toLowerCase().includes("vendor")) setIsFactoryVisible(true)
        else setIsFactoryVisible(false)
        setSelectedUserType(newValue)
    }
    useEffect(() => {
        // console.log("ManageUsersData", ManageUsersData)
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        // get factories list from fb_fty_database
        if (FbFtyDatabaseData) {
            const fs = [];
            FbFtyDatabaseData.map((fact) => {
                if (!fs.includes(fact)) fs.push(fact["Factory"])
            })
            setFactories(fs);
        }
        const jsonReq = {
            "param_body": "",
            "param_function": "ca_get_user_type"
        };
        fetch(restApiPath + 'getDepartments', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            },
        })
            .then(response => response.json())
            .then(result => {
                if (result.error === "invalid_token") {
                    toastMessage("Session Expired", "warning");

                    sessionStorage.clear()
                    setTimeout(() => {
                        nevigate("/login");
                    }, 5000)
                } else {
                    const { data } = result;
                    if (data) {
                        const tmp = data.split(CH1);
                        setDepartments(tmp);
                    }
                }
            });
        fetch(restApiPath + 'callPGFunction', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            },
            body: JSON.stringify(jsonReq),
        })
            .then(response => response.json())
            .then(result => {
                // console.log("result", result)
                if (result.error === "invalid_token") {
                    toastMessage("Session Expired", "warning");

                    sessionStorage.clear()
                    setTimeout(() => {
                        nevigate("/login");
                    }, 5000)
                } else {
                    if (result.data) {
                        setUserTypes(JSON.parse(result.data))
                        dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    }
                }
            });
    }, [])

    const getContextMenuItems = (params) => {
        let result = [];
        if (params.node !== null && params.node !== undefined) {
            result = [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                {
                    name: "Filter By Value",
                    action: () => filterByCellValue(params),
                    icon: '<i class="fa fa-filter" aria-hidden="true"></i>'
                },
                {
                    name: `Clear Filter "${params.column.colDef.field}"`,
                    action: () => clearFilterByCellValue(params),
                    icon: '<i class="fa fa-times" aria-hidden="true"></i>'
                },
                {
                    name: "Clear All Filter",
                    action: () => clearFilterBtnCall(),
                    icon: '<i class="fa fa-times" aria-hidden="true"></i>'
                },
                'separator',
                {
                    name: 'Add User',
                    action: addNewUser,
                    icon: '<i class="fas fa-user-plus"></i>'
                },
                {
                    name: 'Update User',
                    action: updateUser,
                    icon: '<i class="fas fa-user-edit"></i>'
                },
                {
                    name: 'Delete User',
                    action: openDeleteConfirmation,
                    icon: '<i class="fas fa-user-minus"></i>'
                },
            ]
        } else {
            result = [{
                name: 'Add User',
                action: addNewUser,
                icon: '<i class="fas fa-user-plus"></i>'
            }];
        }

        return result
    };
    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false
        if (event.altKey && event.key === "ArrowDown") {
            const column = ManageUsersColsDef.filter((col) => event.srcElement.innerText === col.headerName);
            const colIndex = getColumnIndex(column[0], gridApi) + 1;
            const colElement1 = document.querySelector("div[aria-colindex='" + (colIndex) + "']");

            const wrapper = colElement1.querySelector(".ag-header-cell-comp-wrapper");
            if (wrapper) {
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            } else {
                const colElement1 = document.querySelector(".ag-header-container");
                const colElement2 = colElement1.querySelector(".ag-header-row.ag-header-row-column");
                const colElement3 = colElement2.querySelector("div[aria-colindex='" + (colIndex) + "']");

                const wrapper = colElement3.querySelector(".ag-header-cell-comp-wrapper");
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = ManageUsersColsDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId)
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) {
                element.focus()
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = ManageUsersColsDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            // gridApi.current.setFocusedCell(0,lastColumn.colId,false)
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus()
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = ManageUsersColsDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(ManageUsersData.length - 1);
            gridApi.current.setFocusedCell(ManageUsersData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };
    const gridOptions = useMemo(() => {
        return {
            cellSelection: {
                handle: { mode: "fill" },
              },
            // processCellForClipboard: (params) => {
            //     let ct = params.column.colDef.myType || "";
            //     if (ct === "") return params.value
            //     else if (ct === "date" && !params.value.includes('/')) return ExcelDateToJsDate(params.value)
            //     else return params.value ? params.value.toString().replace(/[%$]/g, "") : params.value;
            // },
        }
    }, []);

    return (
        <>

            <div className="ag-theme-quartz" id="agGridStl">
                <AgGridReact
                    onGridReady={(event) => onManageUsersGridReady(event)}
                    rowSelection="single"
                    enableColResize={true}
                    columnDefs={ManageUsersColsDef}
                    rowData={ManageUsersData}
                    suppressFieldDotNotation
                    getRowHeight={25}
                    enableSorting={true}
                    enableFilter={true}
                    getContextMenuItems={getContextMenuItems}
                    suppressMenuHide={true}
                    defaultColDef={{
                        sortable: true, menuTabs: ['filterMenuTab', 'generalMenuTab'], wrapHeaderText: true, suppressHeaderKeyboardEvent
                    }}
                    onCellFocused={onCellFocused}
                    // enableRangeSelection={true}
                    gridOptions={gridOptions}
                    
                />
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isAddNewUser}
                onClose={closeCreateProfile}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <DialogTitle sx={{ pl: '0px', pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>Create Profile</DialogTitle>
                    <MDBox>
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>First Name</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            required
                            value={firstNameValue}
                            error={showFirstNameError}
                            success={!showFirstNameError}
                            helperText={firstNameHelperText}
                            placeholder="Enter first name here..."
                            onChange={onFNChange}
                        />
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Last Name</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            required
                            error={showLastNameError}
                            success={!showLastNameError}
                            value={lastNameValue}
                            helperText={lastNameHelperText}
                            placeholder="Enter last name here..."
                            onChange={onLNChange}
                        />
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Email</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            placeholder="name@domain.com"
                            error={showError}
                            success={!showError}
                            helperText={emailHelperText}
                            onChange={onEmailChange}
                        />
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={userTypes}
                                defaultValue={userTypes[0]}
                                onChange={(event, newValue) => habdelTypeChange(event, newValue)}
                                renderInput={(params) => <TextField {...params} label="User Type" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%", marginTop: "10px" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                multiple
                                fullWidth
                                options={departments}
                                defaultValue={departments}
                                onChange={(event, newValue) => setSelectedDepartment(newValue)}
                                renderInput={(params) => <TextField {...params} label="Departments" />}
                            />
                        </div>
                        {
                            isFactoryVisible ? (
                                <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        fullWidth
                                        options={factories}
                                        defaultValue={selectedFactory}
                                        onChange={(event, newValue) => setSelectedFactory(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Factory" />}
                                    />
                                </div>
                            ) : null
                        }
                        <DialogActions style={{ justifyContent: "left", paddingBottom: "0px" }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={addUserProfile}
                            >
                                OK
                            </MDButton>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={closeCreateProfile}
                            >
                                Close
                            </MDButton>
                        </DialogActions>
                    </MDBox>
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isUpdateUser}
                onClose={() => setIsUpdateUser(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <DialogTitle sx={{ pl: '0px', pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>Update Profile</DialogTitle>
                    <MDBox>
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>First Name</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            required
                            value={firstNameValue}
                            error={showFirstNameError}
                            success={!showFirstNameError}
                            helperText={firstNameHelperText}
                            placeholder="Enter first name here..."
                            onChange={onFNChange}
                        />
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Last Name</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            required
                            error={showLastNameError}
                            success={!showLastNameError}
                            value={lastNameValue}
                            helperText={lastNameHelperText}
                            placeholder="Enter last name here..."
                            onChange={onLNChange}
                        />
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Email</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            disabled={true}
                            value={email}
                            placeholder="name@domain.com"
                            error={showError}
                            success={!showError}
                            helperText={emailHelperText}
                            onChange={onEmailChange}
                        />
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={userTypes}
                                defaultValue={selectedUserType}
                                onChange={(event, newValue) => habdelTypeChange(event, newValue)}
                                renderInput={(params) => <TextField {...params} label="User Type" />}
                            />
                        </div>
                        {/* <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                multiple
                                fullWidth
                                options={departments}
                                defaultValue={selectedDepartment}
                                onChange={(event, newValue) => setSelectedDepartment(newValue)}
                                renderInput={(params) => <TextField {...params} label="Departments" />}
                            />
                        </div> */}
                        {
                            isFactoryVisible ? (
                                <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        fullWidth
                                        options={factories}
                                        defaultValue={selectedFactory}
                                        onChange={(event, newValue) => setSelectedFactory(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Factory" />}
                                    />
                                </div>
                            ) : null
                        }
                        <DialogActions style={{ justifyContent: "left", paddingBottom: "0px" }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={updateUserProfile}
                            >
                                OK
                            </MDButton>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={() => setIsUpdateUser(false)}
                            >
                                Close
                            </MDButton>
                        </DialogActions>
                    </MDBox>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
                    Delete User
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete '{email}' user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }} onClick={() => setOpenDeleteConfirm(false)}>No</Button>
                    <Button size="small" style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }} onClick={deleteUser} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
});

ManageUsersTab.propTypes = {
    sendDataChangesBackend: PropTypes.func.isRequired,
    setCurrentGridApi: PropTypes.func.isRequired,

};
export default ManageUsersTab;
