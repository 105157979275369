import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { AgGridReact } from "ag-grid-react";
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';
import { CH1, checkHeihlight, executeDataVerification, getDatePicker, getExcelStyle, isEditable, toastMessage, uniqueValues } from 'layouts/linelist/XL_Utils';
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { HeaderHeight } from 'utils/app.props';
import CheckBoxList from '../CheckBoxList';

const tbl_name = "xl_pd_initial_costing";
const tbl_name_ll = "xl_faherty_linelist";

const PD_Linelist_Assortment_Workflow = ({ setPDToLLAssortedRowIds, setPDToLLRevertedRowIds }) => {
    const dispatch = useDispatch(); //to update redux state variable

    const PDInitialCostingData = useSelector(state => state.PDInitialCostingData);
    const SSNData = useSelector((s) => s.PDInitialCostingDataSSN);
    let unsentCount = useSelector(s => s.PDInitialCostingUnsentCount || 0);

    const tmpColDef = useSelector(state => state.PDInitialCostingColDef ? state.PDInitialCostingColDef : []);
    const [PDInitialCostingColDef, setColDef] = useState([]);
    const PDInitialCostingSorting = useSelector(state => state.PDInitialCostingSorting ? state.PDInitialCostingSorting : []);
    const PDInitialCostingFilters = useSelector(state => state.PDInitialCostingFilters ? state.PDInitialCostingFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);
    const msgArr = useRef([]);
    const [isGridReady, setIsGridReady] = useState(false);

    const eLeftGrid = useRef(null);
    const eRightGrid = useRef(null);

    const [LinelistData, setLineListData] = useState([]);
    const tmpColDefLinelist = useSelector(state => state.LinelistColDef ? state.LinelistColDef : []);
    const [LinelistAssortColDef, setLinelistAssortColDef] = useState([]);
    const LinelistFilters = useSelector(state => state.LinelistFilters ? state.LinelistFilters : {});
    const LinelistSorting = useSelector(state => state.LinelistSorting ? state.LinelistSorting : []);

    const gridApiRight = useRef({});
    const [isGridReadyRight, setIsGridReadyRight] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [style, setStyle] = useState([]);
    const [season, setSeason] = useState([]);
    const [department, setDepartment] = useState([]);
    const [category, setCategory] = useState([]);
    const [seasonOptions, setSeasonOption] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    // const [styleOptions, setStyleOptions] = useState([]);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    useEffect(() => {
        window.history.pushState(null, "Linelist", window.location.href);
        window.addEventListener("popstate", () => {
            window.history.pushState(null, document.title, window.location.href);
        });

        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const rearrageColumns = (colsToMigrate, colDef) => {
        const orderMap = colsToMigrate.reduce((acc, colName, index) => {
            acc[colName] = index;
            return acc;
        }, {});
        const orderedColumns = colDef.sort((a, b) => {
            const orderA = orderMap[a.headerName] !== undefined ? orderMap[a.headerName] : Infinity;
            const orderB = orderMap[b.headerName] !== undefined ? orderMap[b.headerName] : Infinity;
            return orderA - orderB;
        });

        return orderedColumns;
    }

    useEffect(() => {
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        // const colsToMigrate = ["Season Code", "Department", "Category", "Style", "Style Description", "Color Description", "Upload To Linelist Check", "Suite Line ID", "Year"];
        const colsToMigrate = ["Season Code", "Department", "Category", "Style", "Style Description", "Color Description", "Assort", "Merch Comments", "Line Type", "Scenario MSRP", "Suite Line ID", "Year"];
        if (tmpColDef && tmpColDef.length > 0) {
            const tmp = JSON.parse(JSON.stringify(tmpColDef));
            const orderedColumns = rearrageColumns(colsToMigrate, tmp);
            let temp = orderedColumns.filter((col) => colsToMigrate.includes(col.headerName));
            temp = temp.map((col) => {
                let mpCol = col
                mpCol = {
                    headerName: col.headerName,
                    field: col.field,
                    colId: col.colId,
                    width: col.width,
                    editable: false,
                    hide: col.hide,
                    cellEditor: col.cellEditor,
                    cellEditorParams: col.cellEditorParams,
                    suppressKeyboardEvent: col.suppressKeyboardEvent,
                    filter: col.filter,
                    filterParams: col.filterParams,
                    autoHeaderHeight: true,
                    resizable: col.resizable,
                    wrapText: col.wrapText,
                    cellStyle: params => {
                        let key = tbl_name + "|" + col.headerName + "|" + params.node.data.row_id
                        let obj = checkHeihlight(key, highlightCells.current)
                        const editable = isEditable(params, col.field, col.editable, col.insert_editable);
                        if (obj.status === true) {
                            if (obj.colorCode == 1) return { backgroundColor: "cyan" };
                            else if (obj.colorCode == 2) return { backgroundColor: "#d5a7f2" };
                            else if (obj.colorCode == 3) {
                                if (col.type === "dropdown")
                                    return { borderColor: "#2196f3", backgroundColor: "#FDFCEC", color: "black" };
                                return { borderColor: "#2196f3" };
                            } else if (obj.colorCode === 4) {
                                if (col.type === "dropdown")
                                    return { borderColor: "#9B6155", backgroundColor: "#FDFCEC", color: "black" };
                                return { borderColor: "#9B6155" };
                            }
                        } else if (col.type === "dropdown") return { backgroundColor: '#FDFCEC', color: "black" }
                        else if (editable) return { backgroundColor: '#white' }
                        return { backgroundColor: '#f0f0f0' }
                    },
                    valueFormatter: col.valueFormatter,
                    valueParser: col.valueParser,
                }
                if (col.headerName === "Season Code") {
                    mpCol["rowDrag"] = true;
                    mpCol["checkboxSelection"] = true;
                    mpCol["headerCheckboxSelection"] = true;
                    mpCol["headerCheckboxSelectionFilteredOnly"] = true;
                    mpCol["width"] = 105;
                }
                return mpCol;
            })           
            setColDef(temp)
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });

        // const tmpData = MerchAssortmentSandboxData.filter((dt) => dt["Pushed To Linelist"] !== "Yes");
        // const fd = JSON.parse(JSON.stringify(tmpData));
        // setFilteredData(fd);

    }, [PDInitialCostingData])   
    useEffect(() => {
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const colsToMigrate = ["Season Code", "Department", "SKU", "Style", "Style Description", "Color Code", "Color Description", "Previous FOB", "Placeholder/Flash FOB", "Initial Cost", "Suite Line ID"];
        if (tmpColDefLinelist && tmpColDefLinelist.length > 0) {
            const tmp = JSON.parse(JSON.stringify(tmpColDefLinelist));
            // Sort the columns based on the desired order
            const orderedColumns = rearrageColumns(colsToMigrate, tmp);

            let temp = orderedColumns.map((col) => {
                let mpCol = col

                mpCol = {
                    suppressSpanHeaderHeight: true,
                    headerName: col.headerName,
                    field: col.field,
                    colId: col.colId,
                    hide: col.hide,
                    suppressColumnsToolPanel: col.hide,
                    suppressFiltersToolPanel: col.hide,
                    cellEditor: col.type === "dropdown" || col.type === "date" ? col.cellEditor : "",
                    cellEditorParams: col.type === "dropdown" || col.type === "date" ? col.cellEditorParams : "",
                    myType: col.type,
                    suppressKeyboardEvent: col.suppressKeyboardEvent,
                    filter: col.filter,
                    filterParams: col.filterParams,
                    width: col.width,
                    autoHeaderHeight: true,
                    resizable: col.resizable,
                    wrapText: col.wrapText,
                    autoHeaderHeight: true,
                    editable: false,
                    cellStyle: params => {
                        if (params.node.data && params.node.data.row_id.includes("tmp_")) return { backgroundColor: '#8fecf0' }
                        return { backgroundColor: '#f0f0f0' }
                    },
                    valueFormatter: col.valueFormatter,
                    valueParser: col.valueParser,

                }
                if (col.type && (col.type === "qty" || col.type === "price" || col.type === "percentage" || col.type.includes("decimal"))) {
                    mpCol.valueGetter = (params) => {
                        if (params.data) {
                            let value = params.data[col.headerName];
                            if (value !== null && value !== undefined && value !== "") value = Number(value)
                            return value;
                        }
                        mpCol.cellDataType = 'number';
                        mpCol.aggFunc = 'sum';                        
                        return "";
                    }
                    // mpCol.cellDataType = 'number';
                    mpCol.aggFunc = 'sum';
                }
                else if (col.type && col.type === "date") mpCol.cellDataType = 'dateString';
                else {
                    mpCol.cellDataType = 'text';
                    mpCol.enableRowGroup = false;
                    mpCol.enablePivot = false;

                }
                if (col.headerName === "Season Code") {
                    mpCol["rowDrag"] = true;
                    mpCol["checkboxSelection"] = true;
                    mpCol["headerCheckboxSelection"] = true;
                    mpCol["headerCheckboxSelectionFilteredOnly"] = true;
                    mpCol["width"] = 105;
                }
                return mpCol;
            })
            setLinelistAssortColDef(temp)
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }, [LinelistData])

    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;

        // if (PDInitialCostingSorting !== null && PDInitialCostingSorting !== undefined) {
        //     gridApi.current.applyColumnState({
        //         state: PDInitialCostingSorting
        //     })
        // }
        // if (PDInitialCostingFilters !== null && PDInitialCostingFilters !== undefined) {
        //     gridApi.current.setFilterModel(PDInitialCostingFilters);
        // }

        /*
        const tmpData = MerchAssortmentSandboxData.filter((dt) => dt["Pushed To Linelist"] !== "Yes" && dt["row_id"] && dt["row_id"] !== "");
        const columnsToUpdate = ['Season Code', 'Department', 'Category'];
        const data = _.map(tmpData, (row) => {
            // Use _.mapValues to iterate over each row and modify the selected columns
            return _.mapValues(row, (value, key) => {
                if (columnsToUpdate.includes(key) && (value === null || value === undefined)) {
                    return ''; // Set null or undefined to blank
                }
                return value; // Keep other values as is
            });
        });

        const ses = uniqueValues('Season Code', data);
        const dept = uniqueValues('Department', data.filter(row => (!season.length || season.includes(row["Season Code"]))));
        const cat = uniqueValues('Category', data.filter(row => (!season.length || season.includes(row["Season Code"])) && (!department.length || department.includes(row["Department"]))));
        // const sty = uniqueValues('Style', MerchAssortmentSandboxData.filter(row => (!season.length || season.includes(row["Season Code"])) && (!department.length || department.includes(row["Department"])) && (!category.length || category.includes(row["Category"]))))
        */

        const ses = uniqueValues('Season Code', PDInitialCostingData);
        const dept = uniqueValues('Department', PDInitialCostingData.filter(row => (!season.length || season.includes(row["Season Code"]))));
        const cat = uniqueValues('Category', PDInitialCostingData.filter(row => (!season.length || season.includes(row["Season Code"])) && (!department.length || department.includes(row["Department"]))));
        // const sty = uniqueValues('Style', PDInitialCostingData.filter(row => (!season.length || season.includes(row["Season Code"])) && (!department.length || department.includes(row["Department"])) && (!category.length || category.includes(row["Category"]))))

        setSeason(ses)
        setSeasonOption(ses)

        setDepartment(dept)
        setDepartmentOptions(dept)

        setCategory(cat)
        setCategoryOptions(cat)

        // setStyle(sty)
        // setStyleOptions(sty)
        setIsGridReady(true)
    }
    const onCellFocused = (event) => {
        if (event === null || event === undefined) return
        if (gridApi.current === null || gridApi.current === undefined) return
        const focusedCell = event.api.getFocusedCell();
        if (focusedCell === null || focusedCell === undefined) {
            return
        }
        const rIdx = focusedCell.rowIndex
        if (rIdx !== null && rIdx !== undefined) {
            let colId = event.column.colId
            var row = gridApi.current.getDisplayedRowAtIndex(rIdx)
            if (row !== null && row !== undefined && row !== "") {
                let tmpArr = new Array()
                if (colId !== null && colId !== undefined && colId !== "") {
                    tmpArr = colId.split(CH1)
                }
                if (tmpArr.length > 0) {
                    // settimeLineDataFun(tmpArr[0], row.data.row_id, event.column.colDef.field)
                }
            }
        }
    };

    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false;
        if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) element.focus();
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            // gridApi.current.setFocusedCell(0,lastColumn.colId,false)
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus();
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(PDInitialCostingData.length - 1);
            gridApi.current.setFocusedCell(PDInitialCostingData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };
    const onCellValueCh = (event, from = "") => {
        let eventSource = event.source ? event.source : ""; // paste | edit | rangeService    
        if (eventSource !== "") {
            const col = event.colDef.headerName;
            let oldValue = event.oldValue || "";
            const column = tmpColDef.filter((cl) => cl.headerName === col);
            if (column && column.length > 0) {
                const type = column[0].type;
                if (type === "date" && !oldValue.includes('/')) oldValue = ExcelDateToJsDate(oldValue)
            }
            if (from === "keyDown" || event.newValue !== oldValue) {
                const res = executeDataVerification(event, tbl_name, highlightCells.current, gridApi.current);
                if (res.status === false) {
                    event.data[col] = event.oldValue;
                    if (res && res.msg && res.msg !== "") {
                        if (eventSource === "edit") {
                            toastMessage(res.msg, "warning");
                        }
                        else {
                            let aa = res.msg;
                            if (!msgArr.current.includes(aa)) msgArr.current.push(aa)
                        }
                    }
                }
                if (res.status === true)
                    if (!cellValueChanged.current.includes(res.key)) cellValueChanged.current.push(res.key);
                optiFunction(event.colDef.field, event)

                gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
                dispatch({ type: "SET_PDInitialCosting_UNSENT_COUNT", count: unsentCount });
            }
        }
    };
    const optiFunction = (colNm, rowNode) => {
        let arr = highlightCells.current;
        const filteredData = SSNData.filter((i) => i.row_id === rowNode.data.row_id);
        if (filteredData.length > 0) {
            let srcVal = rowNode.data[colNm] || "";
            let trgVal = filteredData[0][colNm] || "";
            let arrVal = { [`${tbl_name}|${colNm}|${rowNode.data.row_id}`]: 3 };
            let arrKey = `${tbl_name}|${colNm}|${rowNode.data.row_id}`;
            if (srcVal.toString() !== trgVal.toString()) {
                let foundObject = arr.find(obj => obj[arrKey] === 3);
                if (foundObject === undefined) {
                    arr.push(arrVal);
                    unsentCount++;
                }
            } else {
                arr = arr.filter((a) => {
                    if (Object.values(a)[0] === 3 && Object.keys(a)[0] === arrKey) {
                        unsentCount--;
                        return false;
                    }
                    return true;
                });
            }
            highlightCells.current = arr;
        }
    };
    const onPasteEnd = () => {
        var msg = "";
        if (msgArr.current.length > 0) {
            msg = msgArr.current.join(CH1)
            toastMessage(msg, "warning");
        }
        msgArr.current = [];
    };
    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                // { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
            ],
        };
    }, []);
    const defaultColDef = useMemo(() => {
        return { sortable: true, menuTabs: ['filterMenuTab', 'generalMenuTab'], wrapHeaderText: true, suppressHeaderKeyboardEvent }
    }, []);
    const filterByCellValue = (p, side) => {
        const tblName = side === "left" ? tbl_name : tbl_name_ll;
        const filters = side === "left" ? PDInitialCostingFilters : LinelistFilters;
        const api = side === "left" ? gridApi : gridApiRight;

        let column = tblName + CH1 + p.column.colDef.field;
        let value = `${p.value}`;
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: [value],
                    filterType: "set"
                }
            ]
        };
        filters[column] = newFilterItem;
        api.current.setFilterModel(filters);
        api.current.onFilterChanged();
    };
    const clearFilterByCellValue = (p, side) => {
        const tblName = side === "left" ? tbl_name : tbl_name_ll;
        const filters = side === "left" ? PDInitialCostingFilters : LinelistFilters;
        const api = side === "left" ? gridApi : gridApiRight;

        let column = tblName + CH1 + p.column.colDef.field;
        delete filters[column];
        api.current.setFilterModel(filters);
        api.current.onFilterChanged();
    };
    const clearFilterBtnCall = (params) => {
        params.api.setFilterModel(null);
    }
    const viewAssorted = () => {
        let column = tbl_name + CH1 + "Assort";
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                {
                    filterType: 'text',
                    type: "contains",
                    filter: 'assorted'
                },
                null,
            ]
        };
        const filters = PDInitialCostingFilters;
        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const viewNonAssorted = () => {
        const column = tbl_name + CH1 + "Assort";
        const newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: [null],
                    filterType: "set"
                }
            ]
        };
        const filters = PDInitialCostingFilters;
        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const viewPdAll = () => {
        const filters = PDInitialCostingFilters;
        const column = tbl_name + CH1 + "Assort";
        delete filters[column];
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const viewUploadedToLl = () => {
        const filters = MerchAssortmentSandboxFilters;
        let column = tbl_name_ll + CH1 + "Upload To Linelist Check";
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                {
                    filterType: 'text',
                    type: "contains",
                    filter: 'uploaded'
                },
                null,
            ]
        };

        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const viewNonUploadedToLl = () => {
        const filters = MerchAssortmentSandboxFilters;
        const column = tbl_name_ll + CH1 + "Upload To Linelist Check";
        const newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: [null],
                    filterType: "set"
                }
            ]
        };
        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const viewAllMerchAssort = () => {
        const filters = MerchAssortmentSandboxFilters;
        const column = tbl_name_ll + CH1 + "Upload To Linelist Check";
        delete filters[column];
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    }
    const getContextMenuItems = (params, side) => {
        let result = [];
        if (params.node !== null && params.node !== undefined) {
            // if (side === "left") {
            //     result = [
            //         {
            //             name: "View",
            //             icon: '<i class="fas fa-receipt"></i>',
            //             subMenu: [
            //                 {
            //                     name: "Uploaded To Linelist",
            //                     action: viewUploadedToLl,
            //                 }, {
            //                     name: "Non Uploaded To Linelist",
            //                     action: viewNonUploadedToLl,
            //                 }, {
            //                     name: "All",
            //                     action: viewAllMerchAssort,
            //                 },
            //             ]
            //         },
            //     ];
            // }
            if (side === "left") {
                result = [
                    {
                        name: "View",
                        icon: '<i class="fas fa-receipt"></i>',
                        subMenu: [
                            {
                                name: "Assorted",
                                action: viewAssorted,
                            }, {
                                name: "Non Assorted",
                                action: viewNonAssorted,
                            }, {
                                name: "All",
                                action: viewPdAll,
                            },
                        ]
                    },
                ];
            }            
            const tmp = [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                'cut',
                'paste',
                , 'separator',
                {
                    name: "Filter By Value",
                    action: (params) => filterByCellValue(params, side),
                    icon: '<i class="fa fa-filter" aria-hidden="true"></i>'
                },
                {
                    name: `Clear Filter "${params.column.colDef.field}"`,
                    action: (params) => clearFilterByCellValue(params, side),
                    icon: '<i class="fa fa-filter-circle-xmark" aria-hidden="true"></i>'
                },
                {
                    name: "Clear All Filter",
                    action: () => clearFilterBtnCall(params),
                    icon: '<i class="fa fa-filter-circle-xmark" aria-hidden="true"></i>'
                },
            ]

            if (result.length > 0)
                result = [...result, ...tmp];
            else
                result = tmp;

            return result
        }
    };
    const gridOptions = useMemo(() => {
        return {
            rowSelection: {
                mode: "multiRow",
                checkboxes: false,
                headerCheckbox: false,
                enableClickSelection: true,
                enableSelectionWithoutKeys: true, // for muti row section without cntrl key
              },
            cellSelection: {
                handle: { mode: "fill" },
              },
            processCellForClipboard: (params) => {
                let ct = params.column.colDef.myType || "";
                if (ct === "") return params.value
                else if (ct === "date" && !params.value.includes('/')) return ExcelDateToJsDate(params.value)
                else return params.value ? params.value.toString().replace(/[%$]/g, "") : params.value;
            },
        }
    }, []);
    const onCellKeyDown = (event) => {
        if (event.event.keyCode === 46) {
            const res = executeDataVerification(event, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                if (res && res.msg && res.msg !== "") {
                    toastMessage(res.msg, "warning");
                }
                gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
            }
        }
    }
    const excelStyles = useMemo(() => { return getExcelStyle() }, []);
    const getRowId = (params) => String(params.data.row_id);

    const addRecordToGrid = (side, data) => {
        // if data missing or data has no it, do nothing
        if (!data || data.row_id == null) {
            return;
        }
        moveDataToLinelistAssortment(data, "dropzone");
    };
    const addGridDropZone = (side, api) => {

        const dropSide = side === 'Left' ? 'Right' : 'Left';
        const dropZone = {
            getContainer: () => (dropSide === 'Right' ? eRightGrid.current : eLeftGrid.current),
            onDragStop: (dragParams) => {
                if (side === 'Left') addRecordToGrid(dropSide.toLowerCase(), dragParams.node.data)
                else if (side === 'Right') moveDataToLinelistAssortmentRevert(dragParams.node.data, "drag")
            },
        };
        api.addRowDropZone(dropZone);
    };
    const onGridReadyRight = (params) => {
        gridApiRight.current = params.api;

        // if (LinelistSorting !== null && LinelistSorting !== undefined) {
        //     gridApiRight.current.applyColumnState({
        //         state: LinelistSorting
        //     })
        // }
        // if (LinelistFilters !== null && LinelistFilters !== undefined) {
        //     gridApiRight.current.setFilterModel(LinelistFilters);
        // }

        setIsGridReadyRight(true)
        params.api.autoSizeColumns(["Style Description", "Color Description"]);
    }
    useEffect(() => {
        if (isGridReady && isGridReadyRight) {
            addGridDropZone('Right', gridApiRight.current);
            addGridDropZone('Left', gridApi.current);
        }
    });
    const moveDataToLinelistAssortment = (data, from = "") => {
        // console.log("data", data)
        const api = gridApiRight.current;
        // do nothing if row is already in the grid, otherwise we would have duplicates

        const selectedNodes = gridApi.current.getSelectedRows();
        const transData = [];
        const updateData = [];
        const rowIds = [];

        if (selectedNodes.length === 0 && from === "") {
            toastMessage("Please select a row from left grid.", "warning");
            return;
        }

        const mData = [];
        gridApiRight.current.forEachNode((node) => mData.push(node.data))
        let transaction;

        if (selectedNodes && selectedNodes.length > 0) {
            selectedNodes.map((data) => {
                // const { data } = node;
                const check = mData.find((dt) => dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                    dt["Category"] === data["Category"] && dt["Style"] === data["Style"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"])

                if (check) {
                    toastMessage("Duplicate data detected.", "warning")
                    return;
                } else {
                    const transfetData = {};
                    LinelistAssortColDef.map((col) => transfetData[col.headerName] = "")

                    // data["Upload To Linelist Check"] = "Yes";
                    data["Assort"] = "Yes";
                    // const pdRowNode = gridApi.current.getRowNode(data.row_id);
                    // pdRowNode?.setDataValue(tbl_name + CH1 + "Assort", "Yes");

                    transfetData["row_id"] = "tmp_" + data["row_id"];
                    transfetData["Year"] = data["Year"];
                    transfetData["Season Code"] = data["Season Code"];
                    transfetData["Department"] = data["Department"];
                    transfetData["Category"] = data["Category"];
                    transfetData["Style"] = data["Style"];
                    transfetData["Style Description"] = data["Style Description"];
                    transfetData["Color Description"] = data["Color Description"];
                    transfetData["MSRP"] = data["Scenario MSRP"];
                    transfetData["Suite Line ID"] = data["Suite Line ID"];

                    transData.push(transfetData);
                    updateData.push(data);
                    rowIds.push(data.row_id)
                }
            })
        } else if (data) {
            const check = mData.find((dt) => dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                dt["Category"] === data["Category"] && dt["Style"] === data["Style"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"])

            if (check) {
                toastMessage("Duplicate data detected.", "warning")
                return;
            }
            const transfetData = {};
            LinelistAssortColDef.map((col) => transfetData[col.headerName] = "")

            data["Assort"] = "Yes";
            // const pdRowNode = gridApi.current.getRowNode(data.row_id);
            // pdRowNode?.setDataValue(tbl_name + CH1 + "Assort", "Yes");

            transfetData["row_id"] = data["row_id"];
            transfetData["Year"] = data["Year"];
            transfetData["Season Code"] = data["Season Code"];
            transfetData["Department"] = data["Department"];
            transfetData["Category"] = data["Category"];
            transfetData["Style"] = data["Style"];
            transfetData["Style Description"] = data["Style Description"];
            transfetData["Color Description"] = data["Color Description"];
            transfetData["MSRP"] = data["Scenario MSRP"];
            transfetData["Suite Line ID"] = data["Suite Line ID"];

            transData.push(transfetData);
            updateData.push(data);
            rowIds.push(data.row_id)
        }

        if (transData.length > 0) {
            transaction = {
                add: transData,
                addIndex: 0
            };
            api.applyTransaction(transaction);
            gridApi.current.applyTransaction({ update: updateData });

            setPDToLLAssortedRowIds(rowIds);
        }
        gridApi.current.deselectAll();
    }
    const moveDataToLinelistAssortmentRevert = (data, from = "") => {
        // console.log("data", data)
        const api = gridApi.current;
        // do nothing if row is already in the grid, otherwise we would have duplicates

        const selectedNodes = gridApiRight.current.getSelectedRows();
        const transData = [];
        const updateData = [];
        const rowIds = [];
        const removeIds = [];        

        if (selectedNodes.length === 0 && from === "") {
            toastMessage("Please select a row from right grid.", "warning");
            return;
        }

        const mData = [];
        gridApi.current.forEachNode((node) => mData.push(node.data))
        let transaction;
        let status = true;

        if (selectedNodes && selectedNodes.length > 0) {
            selectedNodes.map((data) => {
                // const { data } = node;
                const check = mData.find((dt) => dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                    dt["Category"] === data["Category"] && dt["Style"] === data["Style"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"])

                if (check) {
                    const transfetData = check;
                    // transfetData["Upload To Linelist Check"] = "";
                    transfetData["Assort"] = "";

                    const llRid = data.row_id && data.row_id.includes("tmp") ? "" : data.row_id;
                    transData.push(transfetData);
                    updateData.push(data);
                    rowIds.push({ "ll": llRid, "pd": transfetData.row_id });
                    removeIds.push(transfetData.row_id)                    
                } else {
                    status = false;
                }
            })
        } else if (data) {
            const check = mData.find((dt) => dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                dt["Category"] === data["Category"] && dt["Style"] === data["Style"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"])

            if (check) {
                const transfetData = check;
                // transfetData["Upload To Linelist Check"] = "";
                transfetData["Assort"] = "";
                transData.push(transfetData);
                updateData.push(data);

                const llRid = data.row_id && data.row_id.includes("tmp") ? "" : data.row_id;
                rowIds.push({ "ll": llRid, "pd": transfetData.row_id });
                removeIds.push(transfetData.row_id)                
            } else {
                status = false;
            }
        }
        if (!status) {
            toastMessage("No matches found for the selected data.", "warning")
        } else if (transData.length > 0) {
            transaction = {
                update: transData,
            };

            api.applyTransaction(transaction);
            gridApiRight.current.applyTransaction({ remove: updateData });
            setPDToLLRevertedRowIds(rowIds);
            setPDToLLAssortedRowIds(removeIds, "remove")            
        }
        gridApiRight.current.deselectAll();
    }
    useEffect(() => {
        applyFilters();
    }, [season, department, category, style]);

    const applyFilters = () => {
        if (isGridReady) gridApi.current.setFilterModel(null);
        // const tmpData = MerchAssortmentSandboxData.filter((dt) => dt["Pushed To Linelist"] !== "Yes" && dt["row_id"] && dt["row_id"] !== "");
        let tmpData = PDInitialCostingData.filter((dt) => (!dt["Assort"] || !dt["Assort"].includes("Assorted") && dt["row_id"] && dt["row_id"] !== ""));
        const fd = JSON.parse(JSON.stringify(tmpData));

        let data = fd;
        const columnsToUpdate = ['Season Code', 'Department', 'Category'];
        data = _.map(data, (row) => {
            // Use _.mapValues to iterate over each row and modify the selected columns
            return _.mapValues(row, (value, key) => {
                if (columnsToUpdate.includes(key) && (value === null || value === undefined)) {
                    return ''; // Set null or undefined to blank
                }
                return value; // Keep other values as is
            });
        });
        if (season.length) {
            data = data.filter(row =>
                season.map(s => s.toLowerCase()).includes((row["Season Code"] || 'BLANK').toLowerCase())
            );
        }

        if (department.length) {
            data = data.filter(row =>
                department.map(dep => dep.toLowerCase()).includes((row["Department"] || 'BLANK').toLowerCase())
            );
        }
        console.log("category",category)
        if (category.length) {
            data = data.filter(row =>
                category.map(cat => cat.toLowerCase()).includes((row["Category"] || 'BLANK').toLowerCase())
            );
        }

        setFilteredData(data);
    };
    const onSesonChange = (value) => {
        const dept = uniqueValues('Department', PDInitialCostingData.filter(row => (!value.length || value.includes(row["Season Code"]))))
        const cat = uniqueValues('Category', PDInitialCostingData.filter(row => (!value.length || value.includes(row["Season Code"])) &&
            (!dept.length || dept.includes(row["Department"]))))
        const sty = uniqueValues('Style', PDInitialCostingData.filter(row => (!value.length || value.includes(row["Season Code"])) &&
            (!dept.length || dept.includes(row["Department"])) && (!cat.length || cat.includes(row["Category"]))))

        setStyle(sty);
        setCategory(cat);
        setDepartment(dept);
        setSeason(value);
    }
    const onDepartmentChange = (value) => {
        const cat = uniqueValues('Category', PDInitialCostingData.filter(row => (!season.length || season.includes(row["Season Code"])) &&
            (!value.length || value.includes(row["Department"]))))

        const sty = uniqueValues('Style', PDInitialCostingData.filter(row => (!season.length || season.includes(row["Season Code"])) &&
            (!value.length || value.includes(row["Department"])) && (!cat.length || cat.includes(row["Category"]))))

        setStyle(sty);
        setCategory(cat);
        setDepartment(value);
    }
    const onCategoryChanged = (value) => {
        console.log("onCategoryChanged", value)
        setCategory(value);
    }
    const resetSlicer = () => {
        setSeason(seasonOptions)
        setDepartment(departmentOptions)
        setCategory(categoryOptions)
    }
    const [tabPanelHeight, setTabPanelHeight] = useState(370);

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    overflow: 'hidden',
                    borderRadius: 2,
                    position: 'relative',
                    paddingBottom: 0.5,
                    transition: 'all 0.3s ease-in-out',
                    bgcolor: 'background.paper',
                }}
            >
                <Box sx={{ p: 1, height: "110px" }}>
                    <Grid container direction={"row"} justifyContent="top" alignItems="top" spacing={0}>
                        <Grid item xs={11.65}>
                            <Grid container direction="row" justifyContent="top" alignItems="top" spacing={1.5}>
                                <Grid item xs={2.7}>
                                    <CheckBoxList label="Season" items={seasonOptions} selectedValues={season} onSelectionChange={onSesonChange} />
                                </Grid>
                                <Grid item xs={2.7}>
                                    <CheckBoxList
                                        label="Department"
                                        items={departmentOptions}
                                        selectedValues={department}
                                        onSelectionChange={onDepartmentChange}
                                    />
                                </Grid>
                                <Grid item xs={6.6}>
                                    <CheckBoxList
                                        items={categoryOptions}
                                        label="Category"
                                        selectedValues={category}
                                        onSelectionChange={onCategoryChanged}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.35} mt={2}>
                            <Button onClick={resetSlicer} size="small" >Reset</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Paper>
            <Grid container alignItems="center" mt={1}>
                <Grid item xs={5.8}>
                    <div className="ag-theme-quartz" id="agGridStl" ref={eLeftGrid} style={{ height: windowDimensions.height - tabPanelHeight }}>
                        <AgGridReact
                            // enableRangeSelection={true}
                            onCellValueChanged={onCellValueCh}
                            onPasteEnd={onPasteEnd}
                            statusBar={statusBar}
                            suppressFieldDotNotation
                            onGridReady={(event) => onGridReady(event)}
                            onCellFocused={(event) => onCellFocused(event)}
                            onCellKeyDown={onCellKeyDown}
                            // onSortChanged={onSortChanged}
                            // onFilterChanged={onFilterChanged}
                            // rowSelection="multiple"
                            // enableFillHandle={true}
                            allowContextMenuWithControlKey={true}
                            gridOptions={gridOptions}
                            enableColResize={true}
                            columnDefs={PDInitialCostingColDef}
                            rowData={filteredData}
                            // suppressRowClickSelection={false}
                            enterNavigatesVertically={true}
                            enterNavigatesVerticallyAfterEdit={true}
                            components={{ Datepicker: getDatePicker() }}
                            suppressHorizontalScroll={false}
                            suppressMenuHide={true}
                            headerHeight={HeaderHeight}
                            defaultColDef={defaultColDef}
                            excelStyles={excelStyles}
                            getContextMenuItems={(params) => getContextMenuItems(params, "left")}
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            rowDragMultiRow={true}
                            getRowId={getRowId}
                            // rowMultiSelectWithClick={true}
                            enableCheckBox={true}
                        />
                    </div>
                </Grid>
                <Grid item xs={0.3}>
                    <Tooltip title="Move rows from Pd_Initial_Costing to Linelist">
                        <IconButton onClick={() => moveDataToLinelistAssortment(null)}> <KeyboardDoubleArrowRight fontSize='large' align='center' /> </IconButton>
                    </Tooltip>
                    <Tooltip title="Revert">
                        <IconButton onClick={() => moveDataToLinelistAssortmentRevert(null)}> <KeyboardDoubleArrowLeft fontSize='large' align='center' /> </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={5.88}>
                    <div className="ag-theme-quartz" id="agGridStl1" ref={eRightGrid} style={{ height: windowDimensions.height - tabPanelHeight }}>
                        <AgGridReact
                            // enableRangeSelection={true}
                            gridOptions={gridOptions}
                            suppressFieldDotNotation
                            onGridReady={(event) => onGridReadyRight(event)}
                            // rowSelection="multiple"
                            columnDefs={LinelistAssortColDef}
                            rowData={LinelistData}
                            // suppressRowClickSelection={false}
                            enterNavigatesVertically={true}
                            enterNavigatesVerticallyAfterEdit={true}
                            suppressHorizontalScroll={false}
                            suppressMenuHide={true}
                            headerHeight={HeaderHeight}
                            defaultColDef={defaultColDef}
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            statusBar={statusBar}
                            getRowId={getRowId}
                            getContextMenuItems={(params) => getContextMenuItems(params, "right")}
                        // onSortChanged={onSortChangedLl}
                        // onFilterChanged={onFilterChangedLl}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default PD_Linelist_Assortment_Workflow;