import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import { JsDateToExcelDateNumber } from 'layouts/linelist/XL_Utils';
import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";

function ShowChanges({ data, getSelectedChanges }) {
    const gridApi = useRef({})

    const onCellClicked = (e) => {
        const selectedRows = gridApi.current.getSelectedNodes();
        selectedRows.map((row) => {
            const item = row.data;
            // console.log(item["New Changes"])
            if (item["New Changes"].includes("$")) {
                item["New Changes"] = item["New Changes"].replace("$", "")
            } else if (item["New Changes"].includes("%")) {
                item["New Changes"] = item["New Changes"].replace("%", "")
            } else if (item["New Changes"].includes("/")) {
                item["New Changes"] = JsDateToExcelDateNumber(item["New Changes"])
            }
            return item;

        })
        getSelectedChanges(selectedRows)
    }

    const [columnDefs,] = useState([
        { field: 'row_id', headerCheckboxSelection: true, checkboxSelection: true, },
        { field: 'Column Name' },
        {
            field: 'Your Unsent Changes',
            valueFormatter: (params) => {
                if (params && params !== "") {
                    let value = params.value;
                    if (value && value.toString().includes("%")) {
                        value = value.replace("%", "")
                        value = parseFloat(value.trim())
                        if (value > 1) {
                            value = value.toFixed(2) + "%"
                        } else {
                            value = (value * 100).toFixed(2) + "%"
                        }
                    }
                    return value
                }
                return ""
            },
        },
        {
            field: 'New Changes',
            cellStyle: { backgroundColor: '#FFFFBC' },
            valueFormatter: (params) => {
                if (params && params !== "") {
                    let value = params.value;
                    if (value && value.toString().includes("%")) {
                        value = value.replace("%", "")
                        value = parseFloat(value.trim())
                        if (value > 1) {
                            value = value.toFixed(2) + "%"
                        } else {
                            value = (value * 100).toFixed(2) + "%"
                        }
                    }
                    return value
                }
                return ""
            },
        },
    ]);
    const [Data,] = useState(data);

    const ontimeLineGridReady = (params) => {
        gridApi.current = params.api
        gridApi.current?.autoSizeAllColumns()
    };

    const isFirstColumn = (params) => {
        // var displayedColumns = params.columnApi.getAllDisplayedColumns()
        var displayedColumns = params.api.getAllDisplayedColumns()
        var thisIsFirstColumn = displayedColumns[0] === params.column
        return thisIsFirstColumn
    };
    const gridOptions = useMemo(() => {
        return {
            cellSelection: {
                handle: { mode: "fill" },
              },
            processCellForClipboard: (params) => {
                let ct = params.column.colDef.myType || "";
                if (ct === "") return params.value
                else if (ct === "date" && !params.value.includes('/')) return ExcelDateToJsDate(params.value)
                else return params.value ? params.value.toString().replace(/[%$]/g, "") : params.value;
            },
        }
    }, []);
    return (
        <MDBox>

            <div style={{ height: '475px', width: '100%', textAlign: 'left' }} className="ag-theme-quartz">
                <AgGridReact
                    onGridReady={(event) => ontimeLineGridReady(event)}
                    suppressContextMenu
                    enableColResize
                    columnDefs={columnDefs}
                    rowData={Data}
                    onRowSelected={onCellClicked}
                    suppressFieldDotNotation
                    getRowHeight={25}
                    // rowSelection="multiple"
                    // enableFillHandle={true}
                    allowContextMenuWithControlKey={true}
                    gridOptions={gridOptions}
                    // rowMultiSelectWithClick={true}
                    // suppressRowClickSelection={false}
                    defaultColDef={{ sortable: true, headerCheckboxSelection: isFirstColumn, checkboxSelection: isFirstColumn, filter: true, menuTabs: ['filterMenuTab', 'generalMenuTab'] }}
                />
            </div>
        </MDBox>
    )
}
ShowChanges.prototype = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    getSelectedChanges: PropTypes.func.isRequired,
}
export default ShowChanges;