import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MDButton from 'components/MDButton';
import { toastMessage } from 'layouts/linelist/XL_Utils';
import { useEffect, useState } from 'react';

const RowInputDialog = ({ onAddRows, open, onClose, table_name }) => {
    const [numRows, setNumRows] = useState(1);
    const [position, setPosition] = useState('belowRowSelected');

    useEffect(() => {
        if (table_name === "xl_faherty_master_lov")
            setPosition("bottom")
        else
            setPosition("belowRowSelected")
    }, [table_name]);

    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (isNaN(value) || value < 1) {
            setNumRows(value);
            return; // Don't add rows if invalid input
        }
        setNumRows(isNaN(value) ? 1 : Math.max(1, value));
    };

    // Function to handle radio button change
    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleAddRows();
        }
    };

    const handleAddRows = () => {
        if (numRows === "" || isNaN(numRows) || numRows < 1) {
            toastMessage("Please enter value to perform add row.", "warning");
            return;
        }
        onAddRows(numRows, position);
        setNumRows('1');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Add Rows</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    How many rows would you like to add?
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Number of Rows"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={numRows}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ mt: 2 }}
                    error={numRows !== '' && (isNaN(parseInt(numRows)) || parseInt(numRows) < 1)}
                    helperText={
                        numRows !== '' && (isNaN(parseInt(numRows)) || parseInt(numRows) < 1)
                            ? 'Please enter a number greater than 0'
                            : ' '
                    }
                />
                <FormControl sx={{
                    '& .MuiFormLabel-root': {
                        fontWeight: 'normal',
                        color: 'rgba(0, 0, 0, 0.87)', // or any color you want
                        '&.Mui-focused': {  // This prevents color change when focused
                            color: 'rgba(0, 0, 0, 0.87)'  // Same color as normal state
                        }
                    },
                    '& .MuiFormControlLabel-label': {
                        fontWeight: 'normal'
                    }
                }}>
                    <FormLabel>Position</FormLabel>
                    <RadioGroup
                        defaultValue="belowRowSelected"
                        name="controlled-radio-buttons-group"
                        value={position}
                        onChange={handlePositionChange}
                        sx={{ my: 1 }}
                    >
                        <FormControlLabel
                            value="top"
                            control={<Radio />}
                            label="Top"
                            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'normal' } }}
                            disabled={table_name === "xl_faherty_master_lov"  ? true : false}
                        />
                        <FormControlLabel
                            value="belowRowSelected"
                            control={<Radio />}
                            label="Below Row Selected"
                            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'normal' } }}
                            disabled={table_name === "xl_faherty_master_lov"  ? true : false}
                        />
                        <FormControlLabel
                            value="aboveRowSelected"
                            control={<Radio />}
                            label="Above Row Selected"
                            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'normal' } }}
                            disabled={table_name === "xl_faherty_master_lov"  ? true : false}
                        />
                        <FormControlLabel
                            value="bottom"
                            control={<Radio />}
                            label="Bottom"
                            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'normal' } }}
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <MDButton onClick={onClose} color="info" variant="outlined">
                    Cancel
                </MDButton>
                <MDButton onClick={handleAddRows} color="info" >
                    Add Rows
                </MDButton>
            </DialogActions>
        </Dialog>
    );
};
export default RowInputDialog;