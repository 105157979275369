import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SendDataStatistics from  "../SendDataStatistics";

import PropTypes from "prop-types";

import React, { useState } from "react";

function SucessWarning({onOpen, onClose, tbl_name, msg_type, activeFunctionality, msg_content }) {
    if (msg_type === "Success" && (msg_content === "" || msg_content === null || msg_content === undefined)) {msg_content = "Operation successfully completed"}
    if (msg_type === "Warning" && (msg_content === "" || msg_content === null || msg_content === undefined)) {msg_content = "Operation failed due to some technical reasons"}
    return (
        (msg_type === "Success")
            ?
            <Dialog
                open={onOpen}
                onClose={onClose}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                {(activeFunctionality !== "")
                    ?
                    <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'left' }}>Status : {activeFunctionality}</DialogTitle>
                    :
                    <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'left' }}>Status</DialogTitle>
                }
                <DialogContent style={{ textAlign: "center", paddingBottom: "5px", maxWidth: "450px" }}>
                    <CheckCircleIcon style={{ fontSize: 80, color: "#07BC0C", height: 70, width: 70 }} />
                    <MDTypography variant="h6" gutterBottom style={{ color: "#07BC0C", marginBottom: "10px" }}>
                        Cheers!
                    </MDTypography>
                    <MDTypography variant="body1" style={{ fontSize: '15px', marginBottom: "7px" }}>
                        {msg_content}
                    </MDTypography>
                    {(activeFunctionality === "Send Changes")
                        ?
                        <SendDataStatistics table_name={tbl_name} />
                        : null
                    }
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', paddingTop: "5px" }}>
                    <MDButton
                        size="small"
                        // color="primary" variant="contained"
                        style={{ backgroundColor: '#07BC0C', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={onClose}
                        autoFocus // Set focus on the "Close" button by default
                    >
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            :
            (msg_type === "Warning")
                ?
                <Dialog
                    open={onOpen}
                    onClose={onClose}
                    aria-labelledby="classic-modal-slide-title-sync-data"
                    aria-describedby="classic-modal-slide-description-sync-data"
                >
                    {(activeFunctionality !== "")
                        ?
                        <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'left' }}>Status : {activeFunctionality}</DialogTitle>
                        :
                        <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'left' }}>Status</DialogTitle>
                    }
                    <DialogContent style={{ textAlign: "center", paddingBottom: "5px", maxWidth: "350px" }}>
                        <ErrorOutlineIcon style={{ fontSize: 80, color: "#BC070C", height: 70, width: 70 }} />
                        <MDTypography variant="h6" gutterBottom style={{ color: "#BC070C", marginBottom: "10px" }}>
                            Something Went Wrong!
                        </MDTypography>
                        <MDTypography variant="body1" style={{ fontSize: '15px', marginBottom: "7px", wordWrap: "break-word" }}>
                            {msg_content}
                        </MDTypography>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', paddingTop: "5px" }}>
                        <MDButton
                            size="small"
                            // color="primary" variant="contained"
                            style={{ backgroundColor: '#BC070C', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                            onClick={onClose}
                            autoFocus // Set focus on the "Close" button by default
                        >
                            Close
                        </MDButton>
                    </DialogActions>
                </Dialog>
                : null
    )
}
SucessWarning.prototype = {
    tbl_name: PropTypes.string.isRequired,
    msg_type: PropTypes.string.isRequired,
}
export default SucessWarning;
